'use client';
import { SetPasswordForm } from '@features/auth-modal-content/ui/set-password-form/set-password';
import { observer } from 'mobx-react-lite';
import { router } from 'next/client';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';

import {
  PromocodeLanding,
  RecoveryForm,
  ResetForm,
  SignInForm,
  SignUpForm,
  SignUpFormMobile,
} from '@/features/auth-modal-content/ui';
import { ModalWrapper } from '@/shared/ui/modal-wrapper';
import { formsMap } from '@/store/authorization/authorization';
import { useStore } from '@/store/context';

export const AuthorizationModal = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const auth = useStore().authorization;
    const searchParams = useSearchParams();
    const promocode = searchParams.get('promocode');
    const router = useRouter();
    const openAuth = searchParams.get('auth');

    useEffect(() => {
      if (promocode) {
        router.push('/');
        auth.openPromoLanding();
      }
      if (openAuth) {
        router.push('/');
        auth.openSignIn();
      }
    });

    return (
      <ModalWrapper
        close={() => auth.closeModal()}
        isOpened={!!auth.openedForm}
        isMobile={isMobile}
      >
        <>
          {auth.openedForm === formsMap.PROMO_LANDING && <PromocodeLanding />}
          {auth.openedForm === formsMap.SIGN_UP &&
            (isMobile ? <SignUpFormMobile /> : <SignUpForm />)}
          {auth.openedForm === formsMap.SIGN_IN && <SignInForm isMobile />}
          {auth.openedForm === formsMap.RECOVERY && <RecoveryForm isMobile />}
          {auth.openedForm === formsMap.RESET && <ResetForm />}
          {auth.openedForm === formsMap.SET_PASSWORD && <SetPasswordForm />}
        </>
      </ModalWrapper>
    );
  },
);
